$green: #007853;
$orange: #f8a053;
$base: #353535;
$blue: #1262c0;

body {
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 400 !important;
  color: $base !important;
  font-size: 16px !important;
  line-height: 23px !important;
  width: 100%;
  transition: .3s;
  outline: none !important;
}

@font-face {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Slab', serif;
  font-style: normal;
  font-display: swap;
}

.page-wrapper {
  overflow-x: hidden;
}

#home {
  height: 100vh;
}

h1,
h2,
h3 {
  font-family: 'Roboto Slab', serif !important;
}

h1,
h2 {
  font-size: 33px !important;
  color: $blue !important;
  font-weight: bold !important;
}

h2 {
  margin-bottom: 1.15rem;
}

h5 {
  font-size: 18px !important;
  font-weight: bold !important;
}

a {
  color: $base !important;
  text-decoration: none !important;
  font-weight: bold;
  outline: none !important;
  transition: .3s;

  &:hover {
    color: $blue !important;
  }
}

img {
  max-width: 100%;
}

button {
  font-weight: bold !important;
  line-height: normal !important;
  font-size: 14px !important;
  border: 0 !important;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif !important;
  outline: none !important;
  transition: .3s;
  cursor: pointer;
}

input,
textarea {
  background-color: transparent;
  border: 0 !important;
  outline: none !important;
  border-bottom: 1px solid white !important;
  margin: 10px 0 !important;
  padding: 10px 10px;
}

.input-icon {
  width: 14%;
  text-align: center;
  border-bottom: 1px solid white;
  margin: 10px 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
}

input {
  width: 86%;
}

textarea {
  width: 100%;
}

.contact-form {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: white;
}

.custom-flex-row {
  display: -ms-flexbox;
  display: flex;
  //justify-content: space-between;
}

.row {
  -ms-flex-align: center;
      align-items: center;
}

.orange-button {
  border-radius: 9999px;
  background-color: white;
  border: 3px solid #1262c0 !important;
  color: $blue !important;
  padding: .8rem 2.5rem;

  &:hover {
    background-color: $blue;
    color: white !important;
  }
}

.white-button {
  border-radius: 9999px;
  background-color: white;
  color: $base !important;
  padding: 1rem 3.25rem;
}

#Menu-back {
  padding: 0 !important;
}

.navbar {
  padding-top: 40px !important;
  padding-bottom: 20px !important;

  .menu-link {
    font-size: 14px;
    line-height: 25px;
  }
}

.menu-link_active {
  border-bottom: 2px solid $blue;
}

.menu-link_disabled {
  border-bottom: 2px solid transparent;
}

.navbar__logo {
  width: 17%;
}

.navbar__phone-href {
  color: $blue !important;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;

  i {
    transform: rotateZ(90deg);
  }
}

#modal-window-products-header {
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 600;
  color: $blue;
}

#intro {
  padding-top: 3.5%;

  span {
    margin-left: 25px;
    opacity: .8;
  }

  p {
    margin: 1.5rem 0 2rem;
  }
}

.section {
  padding: 5.5% 0;
}

.lead-magnet {
  padding: 2.75% 0;
  background-color: $blue;

  h2,
  p,
  span {
    color: white !important;
  }

  p {
    margin-bottom: 0 !important;
  }
}

.lead-magnet-right {
  text-align: right;
}

#numbers {
  padding: 3.5% 0;

  .custom-flex-row {
    -ms-flex-align: center;
        align-items: center;
    margin-top: 20px;
  }

  .numbers__big-number {
    color: $blue !important;
    font-family: 'Roboto Slab', serif;
    font-size: calc(48px + 16 * ((100vw - 320px) / (1280 - 320)));
    font-weight: bold !important;
    line-height: 64px;
    margin-right: 10px;
  }
}

.catalog-base-item,
.catalog-base-item_disabled,
.catalog-subcategory-item {
  margin: 20px 0 0 0;
  padding: 25px;

  h6 {
    font-weight: bold !important;
    margin: 15px 0;
  }

  .orange-button {
    padding: .75rem 1.5rem;
  }

  img {
    height: 50px;
  }
}

.block-order {
  margin-top: 4%;
}

#catalog {
  padding-bottom: 0;

  .catalog-base-item_active {
    background-color: rgba(0, 0, 0, .03);
    border-bottom: 10px solid $green;

    &:after {
      content: '';
      position: absolute;
      left: 30px;
      top: calc(100% + 10px);
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid $green;
    }
  }

  .catalog-subcategory {
    display: none;
    box-shadow: 0 0 20px rgba(0, 0, 0, .15);
    padding-bottom: 20px;
  }

  #subcategory-slider-header {
    margin-bottom: 20px;
  }

  .subcategory-slider-prev {
    color: $green;
    margin-bottom: 15px;
    cursor: pointer;
  }

  .slick-initialized .slick-slide {
    padding: 30px 0;
  }
}

.block-order h6 {
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 800;
  margin-top: 1rem;
}

.block-order p {
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 700;
  margin-top: 1rem;
}

#order {
  padding: 40px 0;
  overflow-x: hidden;

  h3 {
    color: $blue !important;
  }

  .subcategory-sizetable {
    display: none;

    table {
      width: 100%;

      thead {
        tr {
          border-top: 1px solid rgba(0, 120, 83, .7);
          border-bottom: 1px solid rgba(0, 120, 83, .7);
        }
      }

      tr {
        border-bottom: 1px solid rgba(0, 120, 83, .7);

        th,
        td {
          text-align: center;
          //display: inline-block;
          padding: 4px 10px;
          border-left: 1px solid rgba(0, 120, 83, .7);
        }

        th {
          background-color: rgb(0, 120, 83);
          color: white !important;
        }

        th:last-child,
        td:last-child {
          border-right: 1px solid rgba(0, 120, 83, .7);
        }/* one item *//* th:first-child:nth-last-child(1), td:first-child:nth-last-child(1) {
				    width: 100%;
				} *//* two items *//* th:first-child:nth-last-child(2),
				th:first-child:nth-last-child(2) ~ th,
				td:first-child:nth-last-child(2),
				td:first-child:nth-last-child(2) ~ td {
				    width: 60%;
				} *//* three items *//* th:first-child:nth-last-child(3),
				th:first-child:nth-last-child(3) ~ th,
				td:first-child:nth-last-child(3),
				td:first-child:nth-last-child(3) ~ td {
				    width: 30%;
				} *//* four items *//* th:first-child:nth-last-child(4),
				th:first-child:nth-last-child(4) ~ th,
				td:first-child:nth-last-child(4),
				td:first-child:nth-last-child(4) ~ td {
				    width: 20%;
				    font-size: 12px;
				} *//* five items *//* th:first-child:nth-last-child(5),
				th:first-child:nth-last-child(5) ~ th,
				td:first-child:nth-last-child(5),
				td:first-child:nth-last-child(5) ~ td {
				    width: 15%;
				    font-size: 12px;
				} *//* th:first-child, td:first-child{
					width: 40% !important;
					text-align: left;
				} */
      }
    }

    span {
      margin-top: 15px;
      font-size: 12px;
      line-height: 18px;
      font-style: italic;
      display: block;
    }
  }

  form {
    input,
    textarea {
      color: $base !important;
      border-bottom: 1px solid $blue !important;
    }

    i {
      color: $blue;
    }

    .input-icon {
      border-bottom: 1px solid $blue !important;
    }

    .input-wrapper {
      display: -ms-flexbox;
      display: flex;
    }

    input::-webkit-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $base;
      opacity: 1; /* Firefox */
    }

    input:-ms-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $base;
      opacity: 1; /* Firefox */
    }

    input::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $base;
      opacity: 1; /* Firefox */
    }

    input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $base;
    }

    input::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $base;
    }
  }

  #order-header {
    margin-top: 37px;
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 800;
  }
}

#portfolio {
  .orange-button {
    padding: 1rem 1.75rem;
  }

  .portfolio-menu__item {
    position: relative;
    padding-left: 50px;
    line-height: 48px;
    font-size: 21px !important;
    cursor: pointer;
    margin-bottom: 10px;

    &:hover {
      color: $blue !important;
    }
  }

  .portfolio-menu__item_active {
    color: $blue !important;
    font-weight: bold;

    &:before {
      content: '';
      width: 36px;
      height: 2px;
      background-color: $blue;
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -1px;
    }
  }

  .portfolio-menu__item_disabled {
    &:before {
      content: '';
      width: 36px;
      height: 2px;
      background-color: transparent;
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -1px;
    }
  }

  .portfolio-sliders {
    padding: 50px 0;
  }

  #portfolio-slider-prev {
    position: absolute;
    right: 102%;
    top: 43%;
    cursor: pointer;
  }

  #portfolio-slider-next {
    position: absolute;
    left: 102%;
    top: 43%;
    cursor: pointer;
    transform: rotateZ(180deg);
  }
}

#reasons {
  position: relative;

  .reasons-grid {
    img {
      margin-top: 25px;
      height: 45px;
    }

    h5 {
      margin: 20px 0 10px !important;
    }
  }

  button {
    margin-top: 25px;
  }
}

#partners {
  .partners-grid {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin: 25px 0;

    .partners-grid-item {
      width: 12.5%;
      padding: 8px;

      img {
        box-shadow: 0 0 6px rgba(0, 0, 0, .065);
        width: 100%;
        padding: 20px;
        max-height: 121px;
        height: 100%;
      }
    }
  }
}

#reviews {
  padding-top: 3% !important;

  .row {
    position: relative;
  }

  .reviews-slider {
    margin: 25px 0;
  }

  #reviews-slider-prev {
    position: absolute;
    left: 0;
    cursor: pointer;
  }

  #reviews-slider-next {
    position: absolute;
    right: 0;
    cursor: pointer;
    transform: rotateZ(180deg);
  }
}

#advs-slider-prev {
  position: absolute;
  left: 0;
  top: 46%;
  cursor: pointer;
}

#advs-slider-next {
  position: absolute;
  right: 0;
  top: 46%;
  cursor: pointer;
  transform: rotateZ(180deg);
}

#faq {
  .orange-button {
    padding: 1rem 1.75rem;
  }

  .faq-item-wrapper {
    background-color: $blue;
    color: white !important;
    padding: 2.5% 5%;
    margin: 25px 0;
    cursor: pointer;

    p {
      position: relative;
      margin-bottom: 0 !important;
    }
  }

  .faq-item-answer {
    display: none;

    p {
      margin-top: 25px !important;
    }
  }

  .faq-arrow {
    position: absolute;
    right: 0;
    top: 35%;
    transform: rotateZ(180deg);
  }
}

#application {
  background-image: url(/img/app-back.jpg);
  background-size: cover;
  background-position-y: 80%;
  background-repeat: no-repeat;
  color: white !important;

  h2 {
    color: white !important;
    font-size: 24px !important;
    line-height: 1.5;
    letter-spacing: 1px;
  }

  p {
    margin: 25px 0 !important;
  }

  input,
  textarea {
    color: white !important;
  }

  button {
    margin: 25px 0;
  }
}

.modal-shadow {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 9998;
  background-color: rgba(0, 0, 0, .7);
}

#modal-shadow {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 9998;
  background-color: rgba(0, 0, 0, .7);
}

.modal-window-form {
  display: none;
  position: fixed;
  top: 10%;
  left: 34%;
  width: 28%;
  overflow: hidden;
  z-index: 9999;
  background-color: white;
  border-radius: 15px;
  padding: 40px 25px;

  input,
  textarea,
  .input-icon {
    color: $base !important;
    border-color: $base !important;
  }

  .input-icon {
    color: $blue !important;
  }

  h4 {
    font-weight: bold !important;
    color: $blue !important;
  }

  button {
    width: 100%;
    margin-top: 15px;
  }

  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $base;
    opacity: 1; /* Firefox */
  }

  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $base;
    opacity: 1; /* Firefox */
  }

  input::placeholder,
  textarea::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $base;
    opacity: 1; /* Firefox */
  }

  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $base;
  }

  input::-ms-input-placeholder,
  textarea::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $base;
  }

  .close-button {
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
    color: $blue;
    font-size: 24px;
  }
}

#modal-window-form {
  display: none;
  position: fixed;
  top: 10%;
  left: 34%;
  width: 28%;
  overflow: hidden;
  z-index: 9999;
  background-color: white;
  border-radius: 15px;
  padding: 40px 25px;

  input,
  textarea,
  .input-icon {
    color: $base !important;
    border-color: $base !important;
  }

  .input-icon {
    color: $blue !important;
  }

  h4 {
    font-weight: bold !important;
    color: $blue !important;
  }

  button {
    width: 100%;
    margin-top: 15px;
  }

  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $base;
    opacity: 1; /* Firefox */
  }

  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $base;
    opacity: 1; /* Firefox */
  }

  input::placeholder,
  textarea::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $base;
    opacity: 1; /* Firefox */
  }

  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $base;
  }

  input::-ms-input-placeholder,
  textarea::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $base;
  }

  #close-button {
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
    color: $blue;
    font-size: 24px;
  }
}

.modal-window-products {
  display: none;
  position: fixed;
  top: 7%;
  left: 10%;
  width: 80%;
  overflow: hidden;
  z-index: 9999;
  background-color: white;
  border-radius: 15px;
  padding: 40px 25px;

  .close-button {
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
    color: $blue;
    font-size: 24px;
  }

  .catalog-subcategory-tab {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    width: 100%;
    overflow-y: scroll;
    max-height: 75vh;
    padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
    box-sizing: content-box; /* So the width will be 100% + 17px */
  }

  h4,
  h5 {
    color: $green;
    font-weight: bold;
  }
}

footer {
  padding-top: 50px !important;
  padding-bottom: 30px !important;

  p,
  a,
  span {
    font-size: 14px !important;
  }

  h6 {
    font-weight: bold !important;
    color: $blue !important;
  }

  .footer__logo {
    width: 75%;
    margin-bottom: 85px;
  }

  a {
    .social-network-img {
      height: 46px;
      margin: 0 15px 0 0;
    }
  }

  i {
    width: 12%;
    color: $blue;
  }

  .kit-wrapper {
    margin-top: 15px;
    text-align: left;

    a {
      font-weight: normal !important;
    }

    img {
      margin-left: 3px;
    }
  }
}

.slick-slide {
  outline: none !important;
}

.slick-dots {
  bottom: -40px !important;
}

.slick-dots li button:before {
  content: '' !important;
  width: 12px !important;
  height: 12px !important;
  border-radius: 9999px;
  background-color: $blue;
}

.desktop {
  display: block;
}

.desktop-flex {
  display: -ms-flexbox;
  display: flex;
}

.mobile {
  display: none;
}

.ipad {
  display: none;
}

#align {
  -ms-flex-align: start;
      align-items: start;
}

.ficha {
  box-shadow: 0 0 5px 2px rgba(179, 179, 179, 1);
  padding: 6%;

  &:hover {
    box-shadow: 0 0 5px 4px rgba(179, 179, 179, 1);
  }

  .orange-button {
    padding: .79em 2em;
  }
}

#footer-order {
  padding-top: 50px !important;
  padding-bottom: 0 !important;

  p {
    font-weight: 700;
    margin: 0 !important;
  }
}

#menu_mobile {
  width: 100%;
  height: 100vh;
  background: $blue;
  position: fixed;
  top: 0;
  left: -100%;
  transition: .5s;
  overflow-x: scroll;
}

#close-menu {
  width: 6%;
  position: absolute;
  top: 40px;
  right: 20px;
  cursor: pointer;
  height: 23px;
}

#logo-menu {
  top: 35px;
  left: 20px;
  position: absolute;
  width: 194px;
  height: 30px;
}

.partners-second-part {
  height: 137px !important;
}

@media (max-width: 768px) {
  body {
    font-size: 14px !important;
    line-height: 20px !important;
  }

  #order {
    .fa-phone {
      margin-left: 0 !important;
      transform: rotate(0deg);
    }
  }

  #c-3 {
    margin-bottom: 15px;
  }

  .corpus {
    padding-left: 30px !important;
  }

  #modal-window-products-header {
    padding-right: 20px;
  }

  #no-scroll {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  #application {
    background-image: url(/img/fon-form.jpg);
  }

  .footer__logo {
    margin-bottom: 30px !important;
  }

  .desktop {
    display: none !important;
  }

  .desktop-flex {
    display: none !important;
  }

  .mobile {
    display: block;
  }

  .slick-dots {
    bottom: -55px !important;
  }

  #intro {
    .custom-flex-row {
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
    }

    span {
      margin-left: 0;
      margin-top: 10px;
    }

    img {
      margin-top: 40px;
    }
  }

  .catalog-base-item .orange-button,
  .catalog-base-item_disabled .orange-button,
  .catalog-subcategory-item .orange-button {
    padding: .5rem .9rem;
  }

  .lead-magnet-right {
    text-align: left;
  }

  #partners {
    .slick-slide {
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
    }

    .partners-grid-item {
      width: 50%;
      text-align: center;
      padding: 5px;

      img {
        width: 100%;
        max-height: 100px;
        height: 100%;
        box-shadow: inset 0 0 3px rgba(0, 0, 0, .13);
        padding: 20px;
        height: 100%;
      }
    }

    .partners-line-mobile {
      img {
        padding: 40px 20px;
      }
    }
  }

  .subcategory-sizetable {
    overflow-x: scroll;
  }

  h1,
  h2 {
    font-size: 28px !important;
  }

  .orange-button-mobile {
    padding: .8rem 2rem !important;
  }

  .white-button {
    padding: .9em 2.5em;
  }

  .section {
    padding: 10% 0;
  }

  .lead-magnet {
    padding: 7% 0;

    button {
      margin-top: 15px;
    }
  }

  .reviews-slider {
    .d-flex {
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
    }
  }

  #faq .faq-item-wrapper {
    padding: 6%;

    p {
      padding-right: 25px;
    }
  }

  .row {
    .slick-dots {
      margin-left: -15px;
    }
  }

  .modal-window-form,
  .modal-window-products {
    width: 95%;
    left: 2%;
    top: 10px;
    max-height: 92vh;
    overflow: scroll;
    padding: 30px 5px;
  }

  .modal-window-form {
    padding: 30px 15px;
  }

  #modal-window-form,
  .modal-window-products {
    width: 95%;
    left: 2%;
    top: 10px;
    max-height: 92vh;
    overflow: scroll;
    padding: 30px 5px;
  }

  #modal-window-form {
    padding: 30px 15px;
  }

  #portfolio .portfolio-sliders,
  .portfolio-menu {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  #portfolio .portfolio-menu__item {
    cursor: pointer;
    font-size: 18px !important;
    line-height: 18px;
    padding-left: 50px;
    position: relative;
    margin-bottom: 1rem !important;
  }

  .reviews-slider-right {
    padding-top: 20px;
  }

  .navbar {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .navbar .container {
    .fa-phone {
      font-size: 21px;
      margin-left: 2rem;
    }
  }

  .navbar__logo {
    width: 60%;
  }

  input,
  textarea {
    border-radius: 0 !important;
    -webkit-appearance: none;
  }

  .catalog-base-item,
  .catalog-base-item_disabled,
  .catalog-subcategory-item {
    padding: 10px;
  }

  #reviews {
    padding-top: 10% !important;
  }

  .block-order {
    margin-top: 6%;

    p {
      font-size: 16px;
    }
  }

  .ipad {
    display: none;
  }

  .partners-line {
    img {
    }
  }
}

@media (min-width: 769px) {
  .subcategory-sizetable {
    //padding-left: 15px;
  }

  .catalog-subcategory-tab::-webkit-scrollbar {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .container {
    max-width: 720px;
  }

  .mobile {
    display: block;
  }

  .partners-grid-block {
    display: none !important;
  }

  .navbar__phone_href_pad {
    padding-left: 22rem;
  }

  .desktop {
    display: none !important;
  }

  .navbar__logo {
    width: 200px !important;
  }

  .footer__logo {
    margin-bottom: 85px !important;
  }

  .block-order p {
    font-size: .88rem !important;
  }

  .ipad {
    display: block;
  }

  .navbar {
    padding: .5rem 1rem !important;
    padding-bottom: 20px !important;
    padding-top: 40px !important;
  }

  .lead-magnet {
    padding: 7% 0;
  }

  #partners {
    .slick-slide {
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
    }

    .partners-grid-item {
      width: 50%;
      text-align: center;
      padding: 5px;

      img {
        width: 100%;
        max-height: 100px;
        height: 100%;
        box-shadow: inset 0 0 3px rgba(0, 0, 0, .13);
        padding: 20px;
        height: 100%;
      }
    }

    .partners-line-mobile {
      img {
        padding: 40px 20px;
      }
    }
  }

  #application {
    background-image: url(/img/fon-form.jpg);
  }

  .footer__logo {
    width: 90% !important;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .navbar__logo {
    width: 35%;
  }

  .phone-home {
    padding-left: 15em;
  }

  .footer__logo {
    width: 35% !important;
  }
}

@media (max-width: 350px) {
  .catalog-base-item .orange-button,
  .catalog-base-item_disabled .orange-button,
  .catalog-subcategory-item .orange-button {
    padding: .4rem .8rem;
  }

  .catalog-subcategory-item {
    h6 {
      font-size: .9rem !important;
    }
  }

  #logo-menu {
    width: 166px !important;
  }

  .menu-phone {
    top: 100px !important;
  }

  .contact-menu {
    top: 445px !important;
  }
}

.menu-phone {
  position: absolute;
  left: 20px;
  top: 130px;

  a {
    display: block;
    font-size: 20px;
    line-height: 40px;
    color: white !important;
    font-weight: 600 !important;
  }
}

.contact-menu {
  position: absolute;
  left: 20px;
  top: 494px;

  p {
    color: white;
    margin-top: 1rem !important;
  }

  #fb {
    margin-left: 7px;
  }
}

.navbar-phone-order {
  left: 0 !important;
  position: static !important;

  i {
    transform: rotate(90deg) !important;
  }
}

.subcategory-sizetable {
  padding-bottom: .5%;
}

.success_message {
  padding-top: 5%;

  p {
    margin: 0;
  }
}
